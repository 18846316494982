import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ResLocation } from "./LocationModule";

export interface FormRoom {
  id: 1;
  id_room: 1;
  id_floor: 1;
  desc: "";
}

export interface ResRoom {
  id: 1;
  id_room: 1;
  desc: "";
  tx_quotation_locs: [ResLocation];
}

export interface RoomInfo {
  resRoom: ResRoom;
}

@Module
export default class RoomModule extends VuexModule implements RoomInfo {
  templateFormRoom = {
    id: 1,
    id_room: 1,
    id_floor: 1,
    desc: "",
  };
  formRoom = this.templateFormRoom;
  resRoom = {} as ResRoom;

  get getFormRoom() {
    return this.formRoom;
  }

  @Mutation
  [Mutations.setFormRoom](data) {
    this.formRoom = data;
  }

  @Action
  async [Actions.postRoom](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("quotation/room", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  async [Actions.putRoom](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.put("quotation/room/" + data.id, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  async [Actions.dropRoom](id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.delete("quotation/room/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
