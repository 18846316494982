import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ResRoom } from "./RoomModule";

export interface FormItem {
  id: 1;
  id_loc_quotation: 1;
  alt: 1;
  qty: 0;
  lebar_kain: 1.5;
  perkalian: 2.5;
  tinggi: 1;
  lebar: 0;
  opsi: "";
  id_produk: 2;
  priceunit: "10000";
  desc: "";
  id_subproduk1: 0;
  id_subproduk2: 0;
  bukaan: "";
  discount: 20;
  jumlahset: 1;
  ikatan: 1;
}

export interface ResItem {
  id: "";
  id_loc_quotation: "";
  alt: "";
  lebar_kain: "";
  perkalian: "";
  tinggi: "";
  lebar: 0;
  qty: 0;
  opsi: "";
  id_produk: "";
  priceunit: "";
  id_subproduk1: 0;
  id_subproduk2: 0;
  desc: "";
  bukaan: "";
  discount: "";
  jumlahset: "";
  ikatan: "";
}

export interface ItemInfo {
  resItem: ResItem;
}

@Module
export default class ItemModule extends VuexModule implements ItemInfo {
  templateFormItems = {
    id: "",
    id_loc_quotation: "",
    alt: "",
    lebar_kain: "",
    perkalian: "",
    tinggi: "",
    lebar: 0,
    opsi: "",
    qty: 0,
    id_produk: "",
    priceunit: 0,
    id_subproduk1: 0,
    id_subproduk2: 0,
    desc: "",
    bukaan: "",
    tipe: "",
    discount: "",
    jumlahset: "",
    ikatan: "",
  };
  templateFormDefaultItems = {
    id: "",
    id_loc_quotation: "",
    alt: "",
    lebar_kain: "",
    perkalian: "",
    tinggi: "",
    lebar: 0,
    qty: 0,
    opsi: "",
    tipe: "",
    id_produk: "",
    id_subproduk1: 0,
    id_subproduk2: 0,
    priceunit: 0,
    desc: "",
    bukaan: "",
    discount: "",
    jumlahset: "",
    ikatan: "",
  };
  templateFormDefaultItemsAlt = {
    id: "",
    alt: "",
  };
  formItem = this.templateFormItems;
  resItem = {} as ResItem;
  resListItem = [] as Array<ResItem>;
  valueTesItem = 0;

  get getTemplateFormDefaultItemsAlt() {
    return this.templateFormDefaultItemsAlt;
  }

  get getFormItem() {
    console.log(this.formItem);
    return this.formItem;
  }

  get getResListItem() {
    return this.resListItem;
  }

  get getTesItem() {
    return this.valueTesItem;
  }

  @Mutation
  [Mutations.setFormItem](data) {
    this.formItem = data;
  }

  @Mutation
  [Mutations.setClearFormItem]() {
    this.formItem = this.templateFormDefaultItems;
    console.log(this.formItem);
  }

  @Mutation
  [Mutations.setListItem](data) {
    this.resListItem = data;
  }

  @Mutation
  [Mutations.tesItem](data) {
    this.valueTesItem = data;
  }

  @Action
  async [Actions.postItem](data) {
    ApiService.setHeader();
    return ApiService.post("quotation/item", data)
      .then((res) => {
        console.log(res.data);
        // this.context.commit(Mutations.setFormItem, res.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.putItem](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.put("quotation/item/" + data.id, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.context.commit(Mutations.resError, err);
          reject(err);
        });
    });
  }

  @Action
  async [Actions.putAltItem](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.put("quotation/item/" + data.id, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.context.commit(Mutations.resError, err);
          reject(err);
        });
    });
  }

  @Action
  async [Actions.dropItem](id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.delete("quotation/item/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.context.commit(Mutations.resError, err);
          reject(err);
        });
    });
  }

  @Action
  async [Actions.getItemByIdLocation](idloc) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("quotation/item/findbyloc/" + idloc)
        .then((res) => {
          console.log(res);
          this.context.commit(Mutations.setListItem, res.data);
          resolve(res);
        })
        .catch((error) => {
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }
}
