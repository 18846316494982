import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import ProdukModule from "@/store/modulesMaster/ProdukModule";
import QuotationModule from "@/store/modules/QuotationModule";
import CustomerModule from "@/store/modulesMaster/CustomerModule";
import MasterPembayaranModule from "@/store/modulesMaster/MasterPembayaranModule";
import FloorModule from "@/store/modules/FloorModule";
import RoomModule from "@/store/modules/RoomModule";
import ItemModule from "@/store/modules/ItemsModule";
import LocationModule from "@/store/modules/LocationModule";
import MasterFloorModule from "@/store/modulesMaster/MasterFloorModule";
import MasterRoomModule from "@/store/modulesMaster/MasterRoomModule";
import ModalsModule from "@/store/modules/ModalsForm";
import MasterRole from "@/store/modulesMaster/RoleModule";
import UserModule from "@/store/modules/UsersModule";
import LogModule from "@/store/modules/LogModules";
import MasterKategoriProduk from "@/store/modulesMaster/MasterKategoriProdukModule";
import MasterForm from "@/store/modulesMaster/MasterForm";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    ProdukModule,
    QuotationModule,
    CustomerModule,
    UserModule,
    MasterPembayaranModule,
    FloorModule,
    MasterFloorModule,
    MasterRoomModule,
    RoomModule,
    LocationModule,
    ItemModule,
    ModalsModule,
    MasterRole,
    LogModule,
    MasterKategoriProduk,
    MasterForm,
  },
});

export default store;
