import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface FormLog {
  aktifitas: "";
  jenis: "";
  id_quotation: 0;
  creator: 0;
  createdAt: "";
}

export interface ResLog {
  aktifitas: "";
  jenis: "";
  id_quotation: 0;
  creator: 0;
  createdAt: "";
}

export interface LogInfo {
  resLog: ResLog;
}

@Module
export default class LogModule extends VuexModule implements LogInfo {
  templateFormLog = {
    aktifitas: "",
    jenis: "",
    id_quotation: 0,
    creator: 0,
    createdAt: "",
  };
  formLog = this.templateFormLog;
  resLog = {} as ResLog;

  get getFormLog() {
    return this.formLog;
  }

  @Mutation
  [Mutations.setFormLog](data) {
    this.formLog = data;
  }

  @Action
  async [Actions.postLog](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("log", data)
        .then((res) => {
          this.context.commit(Mutations.resError, 200);
          resolve(res);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.resError, response.status);
          reject(response.status);
        });
    });
  }

  @Action
  async [Actions.putLog](data) {
    ApiService.setHeader();
    return ApiService.put("log/" + data.id, data)
      .then((res) => {
        this.context.commit(Mutations.resError, 200);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.resError, response.status);
      });
  }

  @Action
  async [Actions.getLog](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("log")
        .then((res) => {
          this.context.commit(Mutations.resError, 200);
          resolve(res);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.resError, response.status);
          reject(response);
        });
    });
  }

  @Action
  async [Actions.getLogByIdQuo](id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("log/" + id)
        .then((res) => {
          this.context.commit(Mutations.resError, 200);
          resolve(res);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.resError, response.status);
          reject(response);
        });
    });
  }

  @Action
  async [Actions.deleteLog](id) {
    ApiService.setHeader();
    return ApiService.delete("log/" + id)
      .then((res) => {
        this.context.commit(Mutations.resError, 200);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.resError, response.status);
      });
  }
}
