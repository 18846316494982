import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface FormMasterRoom {
  id: 1;
  nama: "";
  createdAt: "";
  updatedAt: "";
}

export interface ResMasterRoom {
  id: 1;
  nama: "";
  type: 0;
  createdAt: "";
  updatedAt: "";
}

export interface MasterRoomInfo {
  resMasterRoom: ResMasterRoom;
}

@Module
export default class MasterRoomModule
  extends VuexModule
  implements MasterRoomInfo
{
  templateFormMasterRoom = {
    id: 1,
    nama: 1,
    type: 0,
    createdAt: "",
    updatedAt: "",
  };
  formMasterRoom = this.templateFormMasterRoom;
  resMasterRoom = {} as ResMasterRoom;
  listMasterRoom = [] as Array<ResMasterRoom>;

  get getFormMasterRoom() {
    return this.formMasterRoom;
  }

  get getListMasterRoom() {
    return this.listMasterRoom;
  }

  @Mutation
  [Mutations.setFormMasterRoom](data) {
    this.formMasterRoom = data;
  }

  @Mutation
  [Mutations.setClearFormMasterRoom]() {
    this.formMasterRoom = this.templateFormMasterRoom;
  }

  @Mutation
  [Mutations.setListMasterRoom](data) {
    this.listMasterRoom = data;
  }

  @Action
  async [Actions.postMasterRoom](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("master/room", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.dropMasterRoom](id) {
    ApiService.setHeader();
    return ApiService.delete("master/room/" + id)
      .then((res) => {
        ("");
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.putMasterRoom](data) {
    ApiService.setHeader();
    return ApiService.put("master/room/" + data.id, data)
      .then((res) => {
        ("");
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.getMasterRoom]() {
    ApiService.setHeader();
    return ApiService.get("master/room")
      .then((data) => {
        this.context.commit(Mutations.resError, data.status);
        this.context.commit(Mutations.setListMasterRoom, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.resError, response.status);
      });
  }
}
