const IdQuotation = "idQuotation" as string;
/**
 * @description get token form localStorage
 */
export const getIdQuotation = (): string | null => {
  return window.localStorage.getItem(IdQuotation);
};

/**
 * @description save token into localStorage
 * @param idQuotation: string
 */
export const saveIdQuotaion = (idQuotation: string): void => {
  window.localStorage.setItem(IdQuotation, idQuotation);
};

/**
 * @description save token into localStorage
 * @param idQuotation: string
 */
export const resetIdQuotaion = (): void => {
  window.localStorage.setItem(IdQuotation, "");
};

export default {
  saveIdQuotaion,
  getIdQuotation,
  resetIdQuotaion,
};
