import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/quotation",
        name: "quotation",
        component: () => import("@/views/quotation/kelolaQuotation.vue"),
        meta: {
          pageTitle: "Quotation",
        },
      },
      {
        path: "/editquotation",
        name: "editquotation",
        component: () => import("@/views/quotation/editQuotation.vue"),
        meta: {
          pageTitle: "Ubah Quotation",
        },
      },
      {
        path: "/masterlantai",
        name: "masterlantai",
        component: () => import("@/views/master/masterFloor.vue"),
        meta: {
          pageTitle: "Master Lantai",
        },
      },
      {
        path: "/masterkategori",
        name: "masterkategori",
        component: () => import("@/views/master/masterKategoriProduk.vue"),
        meta: {
          pageTitle: "Master Kategori Produk",
        },
      },
      {
        path: "/masterform",
        name: "masterform",
        component: () => import("@/views/master/masterForm.vue"),
        meta: {
          pageTitle: "Master Form",
        },
      },
      {
        path: "/masterpelanggan",
        name: "masterpelanggan",
        component: () => import("@/views/master/masterCustomer.vue"),
        meta: {
          pageTitle: "Master Pelanggan",
        },
      },
      {
        path: "/masterproduk",
        name: "masterproduk",
        component: () => import("@/views/master/masterProduk.vue"),
        meta: {
          pageTitle: "Master Produk",
        },
      },
      {
        path: "/masterroom",
        name: "masterroom",
        component: () => import("@/views/master/masterRoom.vue"),
        meta: {
          pageTitle: "Master Ruangan",
        },
      },
      {
        path: "/masterpembayaran",
        name: "masterpembayaran",
        component: () => import("@/views/master/masterPembayaran.vue"),
        meta: {
          pageTitle: "Master Pembayaran",
        },
      },
      {
        path: "/masterrole",
        name: "masterrole",
        component: () => import("@/views/master/masterRole.vue"),
        meta: {
          pageTitle: "Master Role",
        },
      },
      {
        path: "/rekapquotation",
        name: "rekapquotation",
        component: () => import("@/views/rekapQuotation/rekapQuotation.vue"),
        meta: {
          pageTitle: "Rekap Quotation",
        },
      },
      {
        path: "/detailviewquotation",
        name: "detail-detailviewquotation",
        component: () =>
          import("@/views/rekapQuotation/detailViewQuotation.vue"),
        meta: {
          pageTitle: "Detail Quotation",
        },
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/LayoutBuilder.vue"),
        meta: {
          pageTitle: "Layout Builder",
          breadcrumbs: ["Layout"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
