import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ResRoom } from "./RoomModule";

export interface FormFloor {
  floor: 1;
  id_quotation: 1;
}

export interface ResFloor {
  id: 1;
  floor: 1;
  id_quotation: 1;
  createdAt: "";
  updatedAt: "";
  type: 1;
  tx_quotation_rooms: [ResRoom];
}

export interface FloorInfo {
  resFloor: ResFloor;
}

@Module
export default class FloorModule extends VuexModule implements FloorInfo {
  templateFormFloor = {
    id: 1,
    floor: 1,
    id_quotation: 1,
    createdAt: "",
    updatedAt: "",
    type: 1,
  };
  formFloor = this.templateFormFloor;
  resFloor = {} as ResFloor;

  get getFormFloor() {
    return this.formFloor;
  }

  @Mutation
  [Mutations.setFormFloor](data) {
    this.formFloor = data;
  }

  @Mutation
  [Mutations.setClearFormFloor]() {
    this.formFloor = this.templateFormFloor;
  }

  @Action
  async [Actions.postFloor](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("quotation/floor", data)
        .then((res) => {
          resolve(res);
        })
        .catch(({ error }) => {
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.dropFloor](id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.delete("quotation/floor/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
