enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",

  //quotation
  getAllQuotation = "getAllQuotation",
  getQuotationByIdQuotation = "getQuotationByIdQuotation",
  getQuotationByIdMarketing = "getQuotationByIdMarketing",
  getQuotationByIdInduk = "getQuotationByIdInduk",
  postCloneQuotationByInduk = "postCloneQuotationByInduk",
  putQuotation = "putQuotation",
  deleteQuotation = "deleteQuotation",
  downloadQuo = "downloadQuo",
  postQuotation = "postQuotation",
  putQuotationPemb = "putQuotationPemb",
  convertPdf = "convertPdf",
  downloadExcel = "downloadExcel",
  generateExcel = "generateExcel",
  downloadPdf = "downloadPdf",

  //master produk
  getMasterProduk = "getMasterProduk",
  putMasterProduk = "putMasterProduk",
  deleteMasterProduk = "deleteMasterProduk",
  postMasterProduk = "postMasterProduk",
  getSubprodukByInduk = "getSubprodukByInduk",

  //log
  getLog = "getLog",
  getLogByIdQuo = "getLogByIdQuo",
  putLog = "putLog",
  deleteLog = "deleteLog",
  postLog = "postLog",

  //master customer
  postCustomer = "postCustomer",
  putCustomer = "putCustomer",
  dropCustomer = "dropCustomer",
  getCustomer = "getCustomer",

  //masterpembayaran
  postMasterPembayaran = "postMasterPembayaran",
  putMasterPembayaran = "putMasterPembayaran",
  dropMasterPembayaran = "dropMasterPembayaran",
  getMasterPembayaran = "getMasterPembayaran",

  //floor
  postFloor = "postFloor",
  putFloor = "putFloor",
  dropFloor = "dropFloor",
  getFloor = "getFloor",

  //room
  postRoom = "postRoom",
  putRoom = "putRoom",
  dropRoom = "dropRoom",
  getRoom = "getRoom",

  //item
  postItem = "postItem",
  putItem = "putItem",
  dropItem = "dropItem",
  getItem = "getItem",
  getItemByIdLocation = "getItemByIdLocation",
  putAltItem = "putAltItem",

  //location
  postLocation = "postLocation",
  putLocation = "putLocation",
  dropLocation = "dropLocation",
  getLocation = "getLocation",

  //master floor
  postMasterFloor = "postMasterFloor",
  putMasterFloor = "putMasterFloor",
  dropMasterFloor = "dropMasterFloor",
  getMasterFloor = "getMasterFloor",

  //master kategori
  postMasterKategori = "postMasterKategori",
  putMasterKategori = "putMasterKategori",
  dropMasterKategori = "dropMasterKategori",
  getMasterKategori = "getMasterKategori",
  getMasterKategoriProdukForm = "getMasterKategoriProdukForm",

  //master form
  postMasterForm = "postMasterForm",
  putMasterForm = "putMasterForm",
  dropMasterForm = "dropMasterForm",
  getMasterForm = "getMasterForm",

  //master room
  postMasterRoom = "postMasterRoom",
  putMasterRoom = "putMasterRoom",
  dropMasterRoom = "dropMasterRoom",
  getMasterRoom = "getMasterRoom",

  // role
  getRole = "getRole",
  postRole = "postRole",
  putRole = "putRole",
  deleteRole = "deleteRole",

  // action User
  getUser = "getUser",
  postUser = "postUser",
  putUser = "putUser",
  deleteUser = "deleteUser",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_EditProfil = "SET_EditProfil",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  SET_QUOTATION = "SET_QUOTATION",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",

  //produk
  setListMasterProduk = "setListMasterProduk",
  setFormMasterProduk = "setFormMasterProduk",
  setResMasterProduk = "setResMasterProduk",
  setFilterProduk = "setFilterProduk",
  setSelProduk = "setSelProduk",

  //quotation
  setListQuotation = "setListQuotation",
  setFormQuotation = "setFormQuotation",
  addResQuotation = "addResQuotation",
  setClearFormQuotation = "setClearFormQuotation",
  setClearResQuotation = "setClearResQuotation",
  setIdQuotationInLocalStorage = "setIdQuotationInLocalStorage",
  resetIdQuotationInLocalStorage = "resetIdQuotationInLocalStorage",

  //pembayaran
  setListMasterPembayaran = "setListMasterPembayaran",
  setFormMasterPembayaran = "setFormMasterPembayaran",

  //customer
  setListCustomer = "setListCustomer",
  setFormCustomer = "setFormCustomer",
  addResCustomer = "addResCustomer",
  setClearFormCustomer = "setClearFormCustomer",
  setStatusFormCustomer = "setStatusFormCustomer",

  //floor
  setListFloor = "setListFloor",
  setFormFloor = "setFormFloor",
  setClearFormFloor = "setClearFormFloor",

  // mutation role
  listRole = "listRole",
  setFormRole = "setFormRole",

  //room
  setListRoom = "setListRoom",
  setFormRoom = "setFormRoom",
  setClearFormRoom = "setClearFormRoom",

  //item
  setListItem = "setListItem",
  setFormItem = "setFormItem",
  setClearFormItem = "setClearFormItem",
  tesItem = "tesItem",

  //location
  setListLocation = "setListLocation",
  setFormLocation = "setFormLocation",
  setClearFormLocation = "setClearFormLocation",

  //master floor
  setListMasterFloor = "setListMasterFloor",
  setFormMasterFloor = "setFormMasterFloor",
  setClearFormMasterFloor = "setClearFormMasterFloor",

  //master form
  setListMasterForm = "setListMasterForm",
  setFormMasterForm = "setFormMasterForm",
  setClearFormMasterForm = "setClearFormMasterForm",

  //master kategoriProduk
  setListMasterKategori = "setListMasterKategori",
  setFormMasterKategori = "setFormMasterKategori",
  setClearFormMasterKategori = "setClearFormMasterKategori",
  setKategoriProdukForm = "setKategoriProdukForm",

  //log
  setListLog = "setListLog",
  setFormLog = "setFormLog",
  setClearFormLog = "setClearFormLog",

  //master room
  setListMasterRoom = "setListMasterRoom",
  setFormMasterRoom = "setFormMasterRoom",
  setClearFormMasterRoom = "setClearFormMasterRoom",

  resError = "resError",

  // modalForm
  modalsForm = "modalsForm",
  modalsFormTambah = "modalsFormTambah",
  modalsFormEdit = "modalsFormEdit",
  modalFormEditProfil = "modalFormEditProfil",
  modalsFormDelete = "modalsFormDelete",

  // mutation User
  setFormUser = "setFormUser",
  listUser = "listUser",
}

export { Actions, Mutations };
