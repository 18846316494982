import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Roles {
  id: number;
  name: string;
}

export interface role {
  id: 1;
  name: "user";
}

export interface UserInfo {
  objectRole: role;
  listRoleArray: Array<role>;
}

@Module
export default class UserModule extends VuexModule implements UserInfo {
  objectRole = {} as role;
  listRoleArray = [];
  listSelect = [];

  get formRole() {
    return this.objectRole;
  }

  get getListRole() {
    return this.listRoleArray;
  }

  get getListSelect() {
    return this.listSelect;
  }

  @Mutation
  [Mutations.setFormRole](resData) {
    this.objectRole = resData;
  }
  @Mutation
  [Mutations.listRole](resData) {
    this.listRoleArray = resData;
    this.listSelect = resData;
  }

  @Action
  [Actions.postRole](rowData) {
    ApiService.setHeader();
    return ApiService.post("role", rowData)
      .then(({ data }) => {
        // this.context.commit(Mutations.resError, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.resError, response.data.errors);
      });
  }

  @Action
  [Actions.putRole](rowData) {
    ApiService.setHeader();
    return ApiService.put("role/" + rowData.id, rowData)
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.resError, response.data.errors);
      });
  }

  @Action
  [Actions.deleteRole](rowData) {
    ApiService.setHeader();
    return ApiService.delete("role/" + rowData.id)
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.resError, response.data.errors);
      });
  }

  @Action
  [Actions.getRole]() {
    return ApiService.get("role")
      .then((data) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.resError, data.status);
        this.context.commit(Mutations.listRole, data.data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.resError, response.status);
      });
  }
}
