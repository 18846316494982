import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface FormMasterForm {
  id: 1;
  nama_form: "";
  tipe: "";
  jenis: "";
  label: "";
  format_rupiah: "";
  createdAt: "2022-12-03T14:47:06.000Z";
  updatedAt: "2022-12-03T14:47:06.000Z";
}

export interface ResMasterForm {
  id: 1;
  nama_form: "";
  tipe: "";
  jenis: "";
  label: "";
  format_rupiah: "";
  createdAt: "2022-12-03T14:47:06.000Z";
  updatedAt: "2022-12-03T14:47:06.000Z";
}

export interface MasterFormInfo {
  resMasterForm: ResMasterForm;
}

@Module
export default class MasterFormModule
  extends VuexModule
  implements MasterFormInfo
{
  templateFormMasterForm = {
    id: 1,
    nama_form: "",
    tipe: "",
    jenis: "",
    label: "",
    format_rupiah: "",
    createdAt: "2022-12-03T14:47:06.000Z",
    updatedAt: "2022-12-03T14:47:06.000Z",
  };
  formMasterForm = this.templateFormMasterForm;
  resMasterForm = {} as ResMasterForm;
  listMasterForm = [] as Array<ResMasterForm>;

  get getFormMasterForm() {
    return this.formMasterForm;
  }

  get getListMasterForm() {
    return this.listMasterForm;
  }

  @Mutation
  [Mutations.setFormMasterForm](data) {
    this.formMasterForm = data;
  }

  @Mutation
  [Mutations.setClearFormMasterForm]() {
    this.formMasterForm = this.templateFormMasterForm;
  }

  @Mutation
  [Mutations.setListMasterForm](data) {
    this.listMasterForm = data;
  }

  @Action
  async [Actions.postMasterForm](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("master/form", data)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((error) => {
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.putMasterForm](data) {
    ApiService.setHeader();
    return ApiService.put("master/form/" + data.id, data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        this.context.commit(Mutations.resError, err);
      });
  }

  @Action
  async [Actions.dropMasterForm](id) {
    ApiService.setHeader();
    return ApiService.delete("master/form/" + id)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.getMasterForm]() {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("master/form")
        .then((data) => {
          console.log(data);
          this.context.commit(Mutations.resError, data.status);
          this.context.commit(Mutations.setListMasterForm, data.data);
          resolve(data.data);
        })
        .catch(({ err }) => {
          this.context.commit(Mutations.resError, err.status);
          reject(err);
        });
    });
  }
}
