import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface FormMasterPembayaran {
  id: 1;
  nama_rek: "";
  nomor_rek: "";
  nama_bank: "";
}

export interface ResMasterPembayaran {
  id: 1;
  nama_rek: "";
  nomor_rek: "";
  nama_bank: "";
}

export interface MasterPembayaranInfo {
  resMasterPembayaran: ResMasterPembayaran;
}

@Module
export default class MasterPembayaranModule
  extends VuexModule
  implements MasterPembayaranInfo
{
  formMasterPembayaran = {
    id: Number,
    nama_rek: String,
    nomor_rek: String,
    nama_bank: String,
  };
  resMasterPembayaran = {} as ResMasterPembayaran;
  resListMasterPembayaran = [] as Array<ResMasterPembayaran>;

  get getFormMasterPembayaran() {
    return this.formMasterPembayaran;
  }

  get getListMasterPembayaran() {
    return this.resListMasterPembayaran;
  }

  @Mutation
  [Mutations.setListMasterPembayaran](data) {
    this.resListMasterPembayaran = data;
  }

  @Mutation
  [Mutations.setFormMasterPembayaran](data) {
    this.formMasterPembayaran = data;
  }

  @Action
  async [Actions.postMasterPembayaran](data) {
    ApiService.setHeader();
    return ApiService.post("master/pembayaran", data)
      .then((res) => {
        ("");
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.putMasterPembayaran](data) {
    ApiService.setHeader();
    return ApiService.put("master/pembayaran/" + data.id, data)
      .then((res) => {
        ("");
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.dropMasterPembayaran](data) {
    ApiService.setHeader();
    return ApiService.delete("master/pembayaran/" + data.id)
      .then((res) => {
        ("");
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.getMasterPembayaran]() {
    ApiService.setHeader();
    return ApiService.get("master/pembayaran")
      .then((res) => {
        this.context.commit(Mutations.resError, res.status);
        this.context.commit(Mutations.setListMasterPembayaran, res.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.resError, response.status);
      });
  }
}
