import ApiService from "@/core/services/ApiService";
import QuotationService from "@/core/services/QuotationService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ResFloor } from "./FloorModule";

export interface FormQuotation {
  id: number;
  id_marketing: number;
  id_customer: number;
  special_req: string;
  produk: string;
  id_pembayaran: number;
  tx_quotation_floors: string;
  tgl_revisi_terakhir: string;
  no_quotation: string;
  id_induk_quot: number;
  termCondition: string;
  discount: number;
  nominal: number;
}

export interface getQuotation {
  id: 1;
  no_quotation: "";
  special_req: "";
  produk: "";
  id_induk_quot: 1;
  tgl_revisi_terakhir: "";
  createdAt: "";
  updatedAt: "";
  termCondition: "";
  discount: 0;
  nominal: 0;
  tx_quotation_floors: [ResFloor];
  logs: [];
  user: {
    nama: "";
  };
  ref_customer: {
    id: 1;
    nama: "";
    perusahaan: "";
    alamat: "";
    kota: "";
    telepon: "";
  };
  ref_pembayaran: {
    id: 1;
    nama_rek: "";
    nomor_rek: "";
    nama_bank: "";
  };
}

export interface QuotationInfo {
  errors: unknown;
  resQuotation: getQuotation;
}

@Module
export default class QuotationModule
  extends VuexModule
  implements QuotationInfo
{
  errors: unknown;
  templateFormQuotation = {
    id: 1,
    id_marketing: 1,
    id_customer: 1,
    special_req: "",
    produk: "",
    id_pembayaran: "",
    tgl_revisi_terakhir: "",
    no_quotation: "",
    id_induk_quot: null,
    termCondition:
      "- Penawaran harga dan stock barang dapat berubah sewaktu-waktu sebelum down payment\n- Waktu produksi -/+ 14 hari setelah down payment\n- Penawaran yang sudah disetujui tidak dapat dirubah ataupun dibatalkan\n- Syarat pembayaran : 50% down payment sebelum produksi\n50% pelunasan maksimal 1 hari setelah pemasangan\n- Pemasangan yang dilakukan di luar jam kerja (sebelum jam 09.00/setelah jam 18.00) atau di hari libur (tanggal merah/hari minggu)\ndikenakan biaya Rp 200.000/hari\n- 'Maintenance: vacuum only or by professional curtain cleaning company. Tidak disarankan untuk dicopot dan dibawa ke tempat laundry.\n- Jika pemasangan belum bisa dilakukan dikarenakan keadaan lapangan yang belum siap atau dikarenakan hal lain yang diakibatkan pihak customer, maka customer wajib melunaskan pembayaran maksimal 2 minggu setelah info kesiapan produk dari pihak Allura",
    discount: 0,
    nominal: 0,
    user: {
      nama: "",
    },
    ref_customer: {
      id: 1,
      nama: "",
      perusahaan: "",
      alamat: "",
      kota: "",
      telepon: "",
    },
    ref_pembayaran: {
      id: 1,
      nama_rek: "",
      nomor_rek: "",
      nama_bank: "",
    },
  };
  templateFormQuotationPemb = {
    id: 1,
    id_customer: "",
    special_req: "",
    id_pembayaran: "",
    termCondition:
      "- Penawaran harga dan stock barang dapat berubah sewaktu-waktu sebelum down payment\n- Waktu produksi -/+ 14 hari setelah down payment\n- Penawaran yang sudah disetujui tidak dapat dirubah ataupun dibatalkan\n- Syarat pembayaran : 50% down payment sebelum produksi\n50% pelunasan maksimal 1 hari setelah pemasangan\n- Pemasangan yang dilakukan di luar jam kerja (sebelum jam 09.00/setelah jam 18.00) atau di hari libur (tanggal merah/hari minggu)\ndikenakan biaya Rp 200.000/hari\n- 'Maintenance: vacuum only or by professional curtain cleaning company. Tidak disarankan untuk dicopot dan dibawa ke tempat laundry.\n- Jika pemasangan belum bisa dilakukan dikarenakan keadaan lapangan yang belum siap atau dikarenakan hal lain yang diakibatkan pihak customer, maka customer wajib melunaskan pembayaran maksimal 2 minggu setelah info kesiapan produk dari pihak Allura",
    discount: 0,
    nominal: 0,
  };
  formQuotation = this.templateFormQuotation;
  resQuotation = {} as getQuotation;
  listQuotation = [] as Array<getQuotation>;

  get getFormQuotation() {
    return this.formQuotation;
  }

  get getResQuotation() {
    return this.resQuotation;
  }

  get getListQuotation() {
    return this.listQuotation;
  }

  get getIdQuotationInLocalStorage() {
    return QuotationService.getIdQuotation();
  }

  get getTemplateFormQuotationPemb() {
    return this.templateFormQuotationPemb;
  }

  @Mutation
  [Mutations.setListQuotation](data) {
    this.listQuotation = data;
  }

  @Mutation
  [Mutations.setFormQuotation](data) {
    this.formQuotation = data;
  }

  @Mutation
  [Mutations.setClearFormQuotation]() {
    this.formQuotation = this.templateFormQuotation;
  }

  @Mutation
  [Mutations.setIdQuotationInLocalStorage](id) {
    console.log(id);
    QuotationService.saveIdQuotaion(String(id));
  }

  @Mutation
  [Mutations.resetIdQuotationInLocalStorage]() {
    QuotationService.resetIdQuotaion();
  }

  @Mutation
  [Mutations.addResQuotation](data) {
    this.resQuotation = data;
  }

  @Mutation
  [Mutations.setClearResQuotation](data) {
    this.resQuotation = {} as getQuotation;
  }

  @Action
  async [Actions.postQuotation](data) {
    const isi = await data;
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("quotation/", isi)
        .then(({ data }) => {
          // this.context.commit(Mutations.setIdQuotationInLocalStorage, data.id);
          this.context.commit(Mutations.addResQuotation, data);
          resolve(data);
        })
        .catch(({ error }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.convertPdf](idQuo) {
    const isi = await idQuo;
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("convertpdf", isi)
        .then(({ data }) => {
          // this.context.commit(Mutations.setIdQuotationInLocalStorage, data.id);
          // this.context.commit(Mutations.addResQuotation, data);
          resolve(data);
        })
        .catch(({ error }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.downloadExcel](idQuo) {
    const isi = await idQuo;
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("viewpdf", isi)
        .then(({ data }) => {
          // this.context.commit(Mutations.setIdQuotationInLocalStorage, data.id);
          // this.context.commit(Mutations.addResQuotation, data);
          resolve(data);
        })
        .catch(({ error }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.downloadPdf](idQuo) {
    const isi = await idQuo;
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.getBlob("viewpdf", isi)
        .then(({ data }) => {
          // this.context.commit(Mutations.setIdQuotationInLocalStorage, data.id);
          // this.context.commit(Mutations.addResQuotation, data);
          resolve(data);
        })
        .catch(({ error }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.generateExcel](idQuo) {
    const isi = await idQuo;
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("generateexcel", isi)
        .then(({ data }) => {
          // this.context.commit(Mutations.setIdQuotationInLocalStorage, data.id);
          // this.context.commit(Mutations.addResQuotation, data);
          resolve(data);
        })
        .catch(({ error }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.postCloneQuotationByInduk](id) {
    console.log(id);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("quotation/revisi/" + id)
        .then(({ data }) => {
          console.log(data);
          // this.context.commit(Mutations.setIdQuotationInLocalStorage, data.id);
          // this.context.commit(Mutations.addResQuotation, data);
          resolve(data);
        })
        .catch(({ error }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.putQuotationPemb](data) {
    const isi = await data;
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.put("quotation/" + data.form.id, data.formPemb)
        .then((response) => {
          // this.context.commit(Mutations.setIdQuotationInLocalStorage, data.id);
          this.context.commit(Mutations.addResQuotation, isi);
          this.context.commit(Mutations.resError, 200);
          resolve(response.data);
        })
        .catch(({ error }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.resError, error.status);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.deleteQuotation](id) {
    ApiService.setHeader();
    return ApiService.delete("quotation/" + id)
      .then(({ data }) => {
        // this.context.commit(Mutations.setIdQuotationInLocalStorage, data.id);
        // this.context.commit(Mutations.addResQuotation, data.form);
        this.context.commit(Mutations.resError, 200);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.resError, response.status);
      });
  }

  @Action
  async [Actions.getQuotationByIdQuotation](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("quotation/" + data.id)
        .then((res) => {
          this.context.commit(Mutations.addResQuotation, res.data);
          resolve(res);
        })
        .catch((err) => {
          this.context.commit(Mutations.resError, err);
          reject(err);
        });
    });
  }

  @Action
  async [Actions.downloadQuo](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("download/" + data.id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  async [Actions.getQuotationByIdMarketing](id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("quotation/bymarketing/" + id)
        .then((res) => {
          this.context.commit(Mutations.setListQuotation, res.data);
          this.context.commit(Mutations.resError, res.status);
          resolve(res);
        })
        .catch(({ response }) => {
          console.log(response);
          this.context.commit(Mutations.resError, response.status);
          reject(response);
        });
    });
  }

  @Action
  async [Actions.getQuotationByIdInduk](id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("quotation/byquotation/" + id)
        .then((res) => {
          this.context.commit(Mutations.setListQuotation, res.data);
          this.context.commit(Mutations.resError, res.status);
          resolve(res);
        })
        .catch(({ response }) => {
          console.log(response);
          this.context.commit(Mutations.resError, response.status);
          reject(response);
        });
    });
  }
}
