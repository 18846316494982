import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ResMasterForm } from "./MasterForm";
import { ResProduk } from "./ProdukModule";

export interface FormMasterKategori {
  id: 1;
  nama_kategoriproduk: "";
  form: "";
  tampungIdForm: "";
  createdAt: "2022-11-28T08:34:28.000Z";
  updatedAt: "2022-11-28T08:34:28.000Z";
}

export interface ResKategoriProdukForm {
  produk: Array<ResProduk>;
  form: Array<ResMasterForm>;
}

export interface ResMasterKategori {
  id: 1;
  nama_kategoriproduk: "";
  form: "";
  tampungIdForm: "";
  createdAt: "2022-11-28T08:34:28.000Z";
  updatedAt: "2022-11-28T08:34:28.000Z";
}

export interface MasterKategoriInfo {
  resMasterKategori: ResMasterKategori;
  resKategoriProdukForm: ResKategoriProdukForm;
}

@Module
export default class MasterKategoriModule
  extends VuexModule
  implements MasterKategoriInfo
{
  resKategoriProdukForm = {} as ResKategoriProdukForm;
  templateFormMasterKategori = {
    id: 0,
    nama_kategoriproduk: "",
    form: "",
    tampungIdForm: "",
    createdAt: "2022-11-28T08:34:28.000Z",
    updatedAt: "2022-11-28T08:34:28.000Z",
  };
  formMasterKategori = this.templateFormMasterKategori;
  resMasterKategori = {} as ResMasterKategori;
  listMasterKategori = [] as Array<ResMasterKategori>;

  get getFormMasterKategori() {
    return this.formMasterKategori;
  }

  get getListMasterKategori() {
    return this.listMasterKategori;
  }

  get getKatProdForm() {
    return this.resKategoriProdukForm;
  }

  @Mutation
  [Mutations.setFormMasterKategori](data) {
    this.formMasterKategori = data;
  }

  @Mutation
  [Mutations.setKategoriProdukForm](data) {
    this.resKategoriProdukForm = data;
  }

  @Mutation
  [Mutations.setClearFormMasterKategori]() {
    this.formMasterKategori = this.templateFormMasterKategori;
  }

  @Mutation
  [Mutations.setListMasterKategori](data) {
    this.listMasterKategori = data;
  }

  @Action
  async [Actions.postMasterKategori](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("master/kategoriproduk", data)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((error) => {
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.putMasterKategori](data) {
    ApiService.setHeader();
    return ApiService.put("master/kategoriproduk/" + data.id, data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        this.context.commit(Mutations.resError, err);
      });
  }

  @Action
  async [Actions.dropMasterKategori](id) {
    ApiService.setHeader();
    return ApiService.delete("master/kategoriproduk/" + id)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.getMasterKategori]() {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("master/kategoriproduk")
        .then((data) => {
          console.log(data);
          this.context.commit(Mutations.resError, data.status);
          this.context.commit(Mutations.setListMasterKategori, data.data);
          resolve(data.data);
        })
        .catch(({ err }) => {
          this.context.commit(Mutations.resError, err.status);
          reject(err);
        });
    });
  }

  @Action
  async [Actions.getMasterKategoriProdukForm](id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("master/kategoriprodukform/" + id)
        .then((data) => {
          console.log(data);
          this.context.commit(Mutations.resError, data.status);
          this.context.commit(Mutations.setKategoriProdukForm, data.data);
          resolve(data.data);
        })
        .catch(({ err }) => {
          this.context.commit(Mutations.resError, err.status);
          reject(err);
        });
    });
  }
}
