import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ResItem } from "./ItemsModule";

export interface FormLocation {
  id: 1;
  id_q_room: 1;
  desc: "";
  lebar: "";
  tinggi: "";
  additional_info: "";
  jumlah_set: "";
}

export interface ResLocation {
  id: 1;
  id_q_room: 1;
  desc: "contoh desc";
  lebar: "";
  tinggi: "";
  jumlah_set: "";
  additional_info: "";
  tx_quotation_locitems: [ResItem];
}

export interface LocationInfo {
  resLocation: ResLocation;
}

@Module
export default class LocationModule extends VuexModule implements LocationInfo {
  templateFormLocation = {
    id: 1,
    id_q_room: 1,
    desc: "",
    lebar: "",
    tinggi: "",
    additional_info: "",
    jumlah_set: "",
  };
  formLocation = this.templateFormLocation;
  resLocation = {} as ResLocation;

  get getFormLocation() {
    return this.formLocation;
  }

  @Mutation
  [Mutations.setFormLocation](data) {
    this.formLocation = data;
  }

  @Action
  async [Actions.postLocation](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("quotation/loc", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  async [Actions.putLocation](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.put("quotation/loc/" + data.id, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  async [Actions.dropLocation](id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.delete("quotation/loc/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
