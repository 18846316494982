import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface FormMasterFloor {
  id: 1;
  nama: "";
  type: 0;
  createdAt: "";
  updatedAt: "";
}

export interface ResMasterFloor {
  id: 1;
  nama: "";
  type: 0;
  createdAt: "";
  updatedAt: "";
}

export interface MasterFloorInfo {
  resMasterFloor: ResMasterFloor;
}

@Module
export default class MasterFloorModule
  extends VuexModule
  implements MasterFloorInfo
{
  templateFormMasterFloor = {
    id: 1,
    nama: 1,
    type: 0,
    createdAt: "",
    updatedAt: "",
  };
  formMasterFloor = this.templateFormMasterFloor;
  resMasterFloor = {} as ResMasterFloor;
  listMasterFloor = [] as Array<ResMasterFloor>;

  get getFormMasterFloor() {
    return this.formMasterFloor;
  }

  get getListMasterFloor() {
    return this.listMasterFloor;
  }

  @Mutation
  [Mutations.setFormMasterFloor](data) {
    this.formMasterFloor = data;
  }

  @Mutation
  [Mutations.setClearFormMasterFloor]() {
    this.formMasterFloor = this.templateFormMasterFloor;
  }

  @Mutation
  [Mutations.setListMasterFloor](data) {
    this.listMasterFloor = data;
  }

  @Action
  async [Actions.postMasterFloor](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("master/floor", data)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((error) => {
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.putMasterFloor](data) {
    ApiService.setHeader();
    return ApiService.put("master/floor/" + data.id, data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        this.context.commit(Mutations.resError, err);
      });
  }

  @Action
  async [Actions.dropMasterFloor](id) {
    ApiService.setHeader();
    return ApiService.delete("master/floor/" + id)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.getMasterFloor]() {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("master/floor")
        .then((data) => {
          console.log(data);
          this.context.commit(Mutations.resError, data.status);
          this.context.commit(Mutations.setListMasterFloor, data.data);
          resolve(data.data);
        })
        .catch(({ err }) => {
          this.context.commit(Mutations.resError, err.status);
          reject(err);
        });
    });
  }
}
