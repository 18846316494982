import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface FormCustomer {
  id: 1;
  nama: "";
  perusahaan: "";
  alamat: "";
  kota: "";
  telepon: "";
  createdAt: "";
  updatedAt: "";
}

export interface ResCustomer {
  id: 1;
  nama: "";
  perusahaan: "";
  alamat: "";
  kota: "";
  telepon: "";
  createdAt: "";
  updatedAt: "";
}

export interface CustomerInfo {
  resCustomer: ResCustomer;
}

@Module
export default class CustomerModule extends VuexModule implements CustomerInfo {
  templateFormCustomer = {
    id: 1,
    nama: "",
    perusahaan: "",
    alamat: "",
    kota: "",
    telepon: "",
    createdAt: "",
    updatedAt: "",
  };
  statusFormCustomer = false;
  formCustomer = this.templateFormCustomer;
  resCustomer = {} as ResCustomer;
  listMasterCostumer = [] as Array<ResCustomer>;

  get getFormCustomer() {
    return this.formCustomer;
  }

  get getStatusFormCustomer() {
    return this.statusFormCustomer;
  }

  get getTemplateFormCustomer() {
    return this.templateFormCustomer;
  }

  get getResCustomer() {
    return this.resCustomer;
  }

  get getListMasterCostumer() {
    return this.listMasterCostumer;
  }

  @Mutation
  [Mutations.setClearFormCustomer]() {
    this.formCustomer = this.templateFormCustomer;
  }

  @Mutation
  [Mutations.setFormCustomer](data) {
    this.formCustomer = data;
  }

  @Mutation
  [Mutations.addResCustomer](data) {
    this.resCustomer = data;
  }

  @Mutation
  [Mutations.setStatusFormCustomer](data) {
    this.statusFormCustomer = data;
  }

  @Mutation
  [Mutations.setListCustomer](data) {
    this.listMasterCostumer = data;
  }

  @Action
  async [Actions.postCustomer](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("master/customer", data)
        .then((res) => {
          this.context.commit(Mutations.addResCustomer, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          this.context.commit(Mutations.resError, error);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.putCustomer](data) {
    ApiService.setHeader();
    return ApiService.put("master/customer/" + data.id, data)
      .then((res) => {
        this.context.commit(Mutations.addResCustomer, res.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.dropCustomer](data) {
    ApiService.setHeader();
    return ApiService.delete("master/customer/" + data.id)
      .then((res) => {
        this.context.commit(Mutations.addResCustomer, res.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.getCustomer]() {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("master/customer")
        .then((res) => {
          this.context.commit(Mutations.resError, res.status);
          this.context.commit(Mutations.setListCustomer, res.data);
          resolve(res.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.resError, response.status);
          reject(response);
        });
    });
  }
}
