import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface FormProduk {
  id: number;
  nama_produk: string;
  deskripsi: string;
  lebar: string;
  id_induk: number;
  subproduk: number;
  lipatan: string;
  ikatan: string;
  createdAt: string;
  updatedAt: string;
  value: number;
}

export interface ResProduk {
  id: number;
  nama_produk: string;
  deskripsi: string;
  lebar: string;
  id_induk: number;
  subproduk: number;
  lipatan: string;
  ikatan: string;
  createdAt: string;
  updatedAt: string;
  value: number;
}

export interface ProdukInfo {
  errors: unknown;
  formProduk: FormProduk;
  resProduk: ResProduk;
}

@Module
export default class ProdukModule extends VuexModule implements ProdukInfo {
  formProduk = {} as FormProduk;
  resProduk = {} as ResProduk;
  valFilter = "0;1";
  selProduk = 1;
  listMasterProduk = [] as Array<ResProduk>;
  listMasterProduk1 = [] as Array<ResProduk>;
  listMasterProduk2 = [] as Array<ResProduk>;
  listMasterProduk3 = [] as Array<ResProduk>;
  listMasterProduk4 = [] as Array<ResProduk>;
  errors = {};

  get getListMasterProduk() {
    return this.listMasterProduk;
  }

  get getListMasterProduk1() {
    return this.listMasterProduk1;
  }

  get getListMasterProduk2() {
    return this.listMasterProduk2;
  }

  get getListMasterProduk3() {
    return this.listMasterProduk2;
  }

  get getListMasterProduk4() {
    return this.listMasterProduk2;
  }

  get getFormProduk() {
    return this.formProduk;
  }

  get getResError() {
    return this.errors;
  }

  get getValFilter() {
    return this.valFilter;
  }

  get getSelProduk() {
    return this.selProduk;
  }

  @Mutation
  [Mutations.setListMasterProduk](data) {
    console.log(this.listMasterProduk);
    this.listMasterProduk = data;
  }

  @Mutation
  [Mutations.setSelProduk](data) {
    this.selProduk = data;
  }

  @Mutation
  [Mutations.setFormMasterProduk](data) {
    console.log(this.listMasterProduk);
    this.formProduk = data;
  }

  @Mutation
  [Mutations.setFilterProduk](data) {
    console.log(data);
    console.log("siniii");
    this.valFilter = data;
  }

  @Mutation
  [Mutations.setResMasterProduk](data) {
    this.formProduk = this.resProduk;
  }

  @Mutation
  [Mutations.resError](err) {
    this.errors = err;
  }

  @Action
  async [Actions.getMasterProduk]() {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get("master/produk")
        .then((res) => {
          this.context.commit(Mutations.resError, res.status);
          this.context.commit(Mutations.setListMasterProduk, res.data);
          resolve(res);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.resError, response.status);
          reject(response);
        });
    });
  }

  @Action
  async [Actions.getSubprodukByInduk](data) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.get(
        "master/produk/induk/" + data.id_induk + "/" + data.subproduk
      )
        .then((res) => {
          this.context.commit(Mutations.resError, res.status);
          // this.context.commit(Mutations.setListMasterProduk, res.data);
          resolve(res);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.resError, response.status);
          reject(response);
        });
    });
  }

  @Action
  async [Actions.putMasterProduk](data) {
    ApiService.setHeader();
    return ApiService.put("master/produk/" + data.id, data)
      .then((res) => {
        this.context.commit(Mutations.setResMasterProduk, res.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.deleteMasterProduk](data) {
    ApiService.setHeader();
    return ApiService.delete("master/produk/" + data.id)
      .then((res) => {
        this.context.commit(Mutations.setResMasterProduk, res.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }

  @Action
  async [Actions.postMasterProduk](data) {
    ApiService.setHeader();
    return ApiService.post("master/produk", data)
      .then((res) => {
        this.context.commit(Mutations.setResMasterProduk, res.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.resError, error);
      });
  }
}
